import {defineStore} from 'pinia'
import {computed, ref} from 'vue'
import {groupBy, map, filter} from 'lodash-es'
import {useLeague} from '@/composables/useLeague'

export const useDrawerStore = defineStore('drawer', () => {
    const games = ref()

    const sportCount = computed(() => {
      return groupBy(games.value, game => game.sport_id)
    })

    const popularLeagues = computed(() => {
      let data = filter(games.value, game => game.league.popular)
      let leagues = groupBy(data, game => game.league.id)

      leagues = map(leagues, (games) => {
        const league = {
          ...games[0].league,
          sport_id: games[0].sport_id,
          country: games[0].country,
        }

        const {icon} = useLeague(league)
        return {
          id: league.id,
          icon: icon.value,
          sport_id: league.sport_id,
          name_en: league.name_en,
          name_ko: league.name_ko,
          game_count: games.length
        }
      })

      const order = [56, 53, 54, 51, 50]
      leagues.sort((a, b) => {
        return order.indexOf(a.sport_id) - order.indexOf(b.sport_id)
      })

      return leagues
    })

    return {
      games,
      sportCount,
      popularLeagues
    }
  }, {
    persist: {
      paths: ['sportCount', 'popularLeagues']
    }
  }
)
